.home-container {
  margin: auto;
  width: 100vw;
  height: 100vh;
  background-position: center center;
  background-image: url(../assets/images/logo.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: contain;
  align-items: center;
  display: flex;
  justify-content: center;

  .social-links-container {
    width: 80%;
    flex-direction: row;
    margin-top: 50%;
  }

  @media screen and (orientation: portrait) {
    .social-links-container {
      transform: translateY(50%);
    }
  }

  @media screen and (min-width: 1295px) {
    .social-links-container {
      transform: translateY(-70%);
    }
  }
}