.epk {
	&-container {
		margin: 0 0.5em 0.5em;
		font-size: 1em;
	}

	&-row {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		margin: 2em 0 1em;

		h1 {
			font-size: 1.6rem;
			line-height: 1;
			font-family: captureIt;
			font-weight: normal;
			margin: 0;
		}

		h2 {
			font-size: 1rem;
			line-height: 1;
			margin: 0.5rem 0;
		}

		p {
			font-size: 1rem;
			margin: 0;
		}

		&.header-row {
			position: relative;
			margin-top: 0;
			max-height: 250px;
			background: linear-gradient(to bottom, transparent, black 160%), url(../assets/images/godgiven.png);
			background-repeat: no-repeat;
			background-size: cover;
		}

		.logo-container {
			margin-top: 0.5em;
		}

		@media screen and (max-width: 900px) {
			&.header-row {
				max-height: 200px;
			}
		}

		@media screen and (max-width: 780px) {
			&.header-row {
				max-height: 170px;
			}
		}

		@media screen and (max-width: 670px) {
			&.header-row {
				max-height: 110px;
			}
		}

		@media screen and (min-width: 900px) {
			.logo-container, .about-container {
				flex: 1;
			}
		}

		@media screen and (max-width: 899px) {
			.logo-container {
				flex: 1 0 40%;
			}

			.about-container {
				flex: 1 0 59%;
			}
		}
	}
}

.social-links-container {
	&-row {
		margin: 0.5em 0;
		flex-direction: row;
	}

	&-column {
		margin: 0 0.5em;
		flex-direction: column;
	}
}

@media screen and (min-width: 900px) {
	.social-links-container {
		&-row {
			display: none;
		}

		&-column {
			display: flex;
		}
	}
}

@media screen and (max-width: 899px) {
	.social-links-container {
		&-row {
			display: flex;
		}

		&-column {
			display: none;
		}
	}
}