.social-links-container {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.social-link {
	  display: flex;

	  svg {
	    fill: #ffffff;
	    color: #ffffff;
	    width: 45px;
	    height: 45px;
	  }
	}
}