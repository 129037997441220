.media {
	&-container {
		width: 100%;
	}

	&-content {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;
	}

	&-row {
		margin: 0.5rem 0.2rem 0;
	}

	@media screen and (min-width: 1024px) {
		&-row {
			flex: 1 0 24%;
		}
	}

	@media screen and (max-width: 1023px) {
	    &-row {
	      flex: 1 0 28%;
	    }
  	}

  	@media screen and (max-width:670px) {
	    &-row {
	      flex: 1 0 48%;
	    }
  	}
}