.contacts-container {
	.contacts-content {
		text-align: center;

		.contacts-link {
			font-family: captureIt;
			color: inherit;
			text-decoration: none;

			&:hover {
				opacity: 0.5;
			}
		}
	}
}