@import "../mixins.scss";

.about-container {
	cursor: pointer;
	overflow: hidden;
	position: relative;
	padding: 0.5em;
	box-sizing: border-box;

	&:before {
		@include truncate-text;
	}

	@media screen and (max-width: 850px) {
	    &:before {
			@include truncate-text(80px);
		}
  	}

  	@media screen and (max-width: 670px) {
	    &:before {
			@include truncate-text(60px);
		}
  	}

	&.full {
		z-index: 2;
		position: sticky;
		background-color:rgba(0,0,0,0.8);
		overflow: initial;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;

		& > div {
			padding: 0.5em;
			width: calc(100% - 1em);
			height: calc(100% - 1em);
			border: 1px #fff solid;
		}

		&:before {
			display: none;
		}
	}
}